import httpClient from "~api/httpClient";

export const fetchAccountDetails = (params) => {
  return httpClient({
    method: "GET",
    url: "/business-owner/admin/settings/account-details",
    params,
  });
};

export const updateAccountDetails = (accountInfo, params) => {
  return httpClient({
    params,
    method: "PUT",
    url: "/business-owner/admin/settings/account-details",
    data: accountInfo,
  });
};

export const createOrUpdateRegion = (region) => {
  return httpClient({
    method: "POST",
    url: "/business-owner/admin/settings/regions",
    data: {
      region,
    },
  });
};

export const fetchAccountSettings = () => {
  return httpClient({
    method: "GET",
    url: "/business-owner/account/settings",
  });
};

export const updateAccountSettings = (data) => {
  return httpClient({
    method: "PATCH",
    url: "/business-owner/admin/settings/account/settings",
    data,
  });
};

export const updateConvenienceFeeName = (data) => {
  return httpClient({
    method: "PATCH",
    url: "/business-owner/admin/settings/account/settings/convenience-fee-name",
    data,
  });
};

export const createStripeConnectedAccount = () => {
  return httpClient({
    method: "POST",
    url: "/business-owner/admin/settings/account/bank/register",
  });
};

export const fetchStripeVerificationLink = (linkType) => {
  return httpClient({
    method: "GET",
    url: "/business-owner/admin/settings/account/bank/verification-link",
    params: {linkType},
  });
};

export const createBankAccount = (data) => {
  return httpClient({
    method: "POST",
    url: "/business-owner/admin/settings/account/bank/account-details",
    data,
  });
};

export const deleteBagNoteTag = (id) => {
  return httpClient({
    method: "POST",
    url: `/business-owner/admin/settings/account/bag-note-tag/${id}/delete`,
  });
};

export const deleteCustomerNoteTag = (id) => {
  return httpClient({
    method: "DELETE",
    url: `/business-owner/admin/settings/account/customer-note-tag/${id}`,
  });
};

export const getAccountInfo = () => {
  return httpClient({
    method: "GET",
    url: `/business-owner/account`,
  });
};

// new API to fetch all PayoutAccount entries for a given business
export const fetchAllPayoutAccounts = () => {
  return httpClient({
    method: "GET",
    url: "/business-owner/admin/settings/account/payout-accounts",
  });
};

// new API to create a PayoutAccount for a given business
export const createNewPayoutAccount = () => {
  return httpClient({
    method: "POST",
    url: "/business-owner/admin/settings/account/payout-accounts/create",
  });
};

// new API to generate the verification link for a given PayoutAccount
export const getStripeConnectOnboardingVerificationLink = (accountId) => {
  return httpClient({
    method: "GET",
    url: `/business-owner/admin/settings/account/payout-accounts/${accountId}/verification-link/onboarding`,
  });
};

// new API to attach a bank account to a given PayoutAccount
export const attachBankAccountToPayoutAccount = (data) => {
  return httpClient({
    method: "POST",
    url: "/business-owner/admin/settings/account/payout-accounts/bank-account/attach",
    data,
  });
};

// new API to set the PayoutAccount as the default account
export const setPayoutAccountAsDefault = (data) => {
  return httpClient({
    method: "PATCH",
    url: "/business-owner/admin/settings/account/payout-accounts/default",
    data,
  });
};

// new API to assign PayoutAccount entries to stores
export const mapPayoutAccountToStore = (data) => {
  return httpClient({
    method: "PATCH",
    url: "/business-owner/admin/settings/account/payout-accounts/assign-stores",
    data,
  });
};

// new API to generate the account update link for a given PayoutAccount
export const getStripeConnectAccountUpdateLink = (accountId) => {
  return httpClient({
    method: "GET",
    url: `/business-owner/admin/settings/account/payout-accounts/${accountId}/verification-link/account-update`,
  });
};
