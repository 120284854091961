import * as Sentry from "@sentry/react";
import {useQuery} from "@tanstack/react-query";

import {
  fetchQtyUpdaterConnectionUrl,
  FetchQtyUpdaterConnectionUrlResponse,
} from "~api/websocket-connection/qtyUpdater";
import {ApiError} from "~types/api";
import {callErrorToast} from "~utils/toasts";

const QUERY_KEY = "qty-updater-connection-url";
export const getQtyUpdaterConnectionUrlQueryKey = () => [QUERY_KEY];

export const useQtyUpdaterConnectionUrl = ({
  enabled = true,
}: {enabled?: boolean} = {}) => {
  return useQuery<unknown, ApiError, FetchQtyUpdaterConnectionUrlResponse | null>({
    queryKey: getQtyUpdaterConnectionUrlQueryKey(),
    queryFn: async () => {
      const {data} = await fetchQtyUpdaterConnectionUrl();
      return data;
    },
    onError: (error) => {
      Sentry.captureException(error);
      callErrorToast({
        content: "An error occurred while fetching the QTY Updater connection URL",
      });
    },
    enabled,
  });
};
