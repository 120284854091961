import * as Sentry from "@sentry/react";
import {WebSocket} from "partysocket";
import ReconnectingWebSocket from "partysocket/ws";
import {useCallback, useEffect, useRef} from "react";

import {CustomEventName, useCustomEvent} from "~hooks/useCustomEvent";

import {useQtyUpdaterConnectionUrl} from "~api/websocket-connection/queries/useQtyUpdaterConnectionUrl";
import {REACT_ENV, VITE_ENV_TYPES} from "~utils/config";

// WS API has idle limit, see: https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html#apigateway-execution-service-websocket-limits-table
const PING_INTERVAL_MS = 7 * 60 * 1000; // 7 minutes
const MAX_RETRIES_WITH_UPDATED_URL = 5;

enum QtyUpdaterEventTypes {
  PING = "ping",
  INVENTORY_UPDATED = "inventoryUpdated",
}

export interface EventData {
  type: QtyUpdaterEventTypes;
}

export interface InventoryUpdatedEventData extends EventData {
  type: QtyUpdaterEventTypes.INVENTORY_UPDATED;
  storeId: number;
  inventoryId: number;
  quantity: number;
}

export const useQtyUpdaterWebSocket = () => {
  const wsConnectionRef = useRef<ReconnectingWebSocket | null>(null);
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const retryCountRef = useRef<number>(0);

  const {publish} = useCustomEvent(CustomEventName.STORE_INVENTORY_UPDATED);

  const {data: connectionData, refetch: refetchConnectionUrl} =
    useQtyUpdaterConnectionUrl();

  const clearPingInterval = useCallback(() => {
    clearInterval(pingIntervalRef.current || undefined);
    pingIntervalRef.current = null;
  }, []);

  const startPingInterval = useCallback(() => {
    if (wsConnectionRef.current?.url) {
      clearPingInterval();

      const channelId = new URL(wsConnectionRef.current.url).searchParams.get(
        "channelId"
      );
      const pingMessage = {
        channelId,
        data: JSON.stringify({type: QtyUpdaterEventTypes.PING}),
      };

      pingIntervalRef.current = setInterval(() => {
        wsConnectionRef.current?.send(JSON.stringify(pingMessage));
      }, PING_INTERVAL_MS);
    }
  }, [clearPingInterval]);

  const setupWebSocket = useCallback(
    (url: string) => {
      wsConnectionRef.current?.close();

      const wsConnection = new WebSocket(url, [], {
        WebSocket: window.WebSocket,
        connectionTimeout: 4000,
        minReconnectionDelay: 2000,
        maxReconnectionDelay: 60000,
        maxEnqueuedMessages: 10,
        debug: REACT_ENV === VITE_ENV_TYPES.LOCAL,
      });

      wsConnectionRef.current = wsConnection;

      wsConnection.onopen = () => {
        startPingInterval();
      };

      wsConnection.onmessage = (event) => {
        const parsedData: EventData = JSON.parse(event.data);

        if (parsedData.type === QtyUpdaterEventTypes.INVENTORY_UPDATED) {
          publish(parsedData as InventoryUpdatedEventData);
        }

        startPingInterval();
      };

      wsConnection.onerror = (errorEvent) => {
        if (retryCountRef.current < MAX_RETRIES_WITH_UPDATED_URL) {
          refetchConnectionUrl().then(({data}) => {
            if (data?.url) {
              retryCountRef.current += 1;
              setupWebSocket(data.url);
            }
          });
        } else {
          const error = errorEvent.error || new Error(errorEvent.message);
          Sentry.captureException(error);
        }
      };
    },
    [startPingInterval, publish, refetchConnectionUrl]
  );

  useEffect(() => {
    if (connectionData?.url) {
      if (wsConnectionRef.current) {
        wsConnectionRef.current.close();
      }
      setupWebSocket(connectionData.url);
    }

    return () => {
      wsConnectionRef.current?.close();
      wsConnectionRef.current = null;
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
    };
  }, [connectionData?.url, setupWebSocket]);
};
