export enum ORIGIN_TYPES {
  LIVE_LINK = "LIVE_LINK",
  DRIVER_APP = "DRIVER_APP",
  EMPLOYEE_APP = "EMPLOYEE_APP",
  INTERNAL_MANAGER = "INTERNAL_MANAGER",
  RESIDENTIAL_APP = "RESIDENTIAL_APP",
  BUSINESS_MANAGER = "BUSINESS_MANAGER",
  BOT = "BOT",
  MOBO = "MOBO",
}
