import * as Sentry from "@sentry/react";
import moment from "moment";

import {
  HELP_BUTTON_ID,
  INTERCOM_COMPANY_CUSTOM_ATTRIBUTES,
  LOCAL_CURRENCY,
  LOCALE,
} from "../constants";
import {VITE_LIVE_LINK_URL} from "../utils/config";

export const convertSecondsToMinutes = (totalSeconds) => {
  if (!Number.isFinite(totalSeconds) || totalSeconds < 0) {
    return null;
  } else {
    const minutes = Math.ceil(totalSeconds / 60);
    return `${minutes} ${minutes > 1 ? "mins" : "min"}`;
  }
};

export const createMultipleRequestsAtIntervals = (
  callBack,
  ms = 1000,
  finishStep = 100
) =>
  new Promise((resolve, reject) => {
    let startStep = 0;
    setTimeout(async function timer() {
      try {
        const {result, statusFinishRequest} = await callBack();

        if (startStep === finishStep) {
          reject({message: "Request limit expired"});
          return;
        }

        if (!statusFinishRequest) {
          startStep++;
          setTimeout(timer, ms);
        } else {
          resolve(result);
          return;
        }
      } catch (error) {
        Sentry.captureException(error);
        reject(error);
      }
    });
  });

export const formattedUserId = (id) => {
  const userId = id.toString();
  let length = 6 - userId.length;

  return `${"0".repeat(length)}${userId}`;
};

export const formatToThousandRoundedNumber = (value) => {
  let number = Number(value);
  if (!number) {
    return 0;
  }

  return `${number.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}`;
};

/**
 * @param {String} template - The template to parse, should use {{placeholder}} to replace with data values
 * @param {Object} data - The data to use in the template (placeholder as key/value)
 * @returns {String} - The parsed string
 */
export const parseTemplate = (template, data = {}) => {
  return template && data && typeof data === "object"
    ? template.replace(/\{\{(.*?)\}\}/gi, (match, key) => data[key] || "")
    : "Description was not specified or data has invalid format";
};

export const formatCurrency = (
  value,
  includeDecimal = true,
  culture = LOCALE,
  currency = LOCAL_CURRENCY
) => {
  const formatter = new Intl.NumberFormat(culture, {
    style: "currency",
    currency,
  });
  const formattedValue = formatter.format(value);
  return includeDecimal
    ? formattedValue
    : formattedValue.substring(0, formattedValue.length - 3);
};

export const formatLocaleNumber = (value, culture = LOCALE, options = {}) => {
  const formatter = new Intl.NumberFormat(culture, options);
  const formattedValue = formatter.format(value);
  return formattedValue;
};

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
}

const fixDateForAllBrowsers = (dateString) => dateString.replace(/-/g, "/");

export const formatChartDates = (dates, filterPeriod) => {
  const startDateMoment = moment(filterPeriod.startDate);
  const endDateMoment = moment(filterPeriod.endDate);
  const periodRangeLengthDays = endDateMoment.clone().diff(startDateMoment, "days", true);
  const DATE_FORMAT = periodRangeLengthDays > 1 ? "MMM DD" : "HH:mm";
  return dates.map((d) => {
    return moment(fixDateForAllBrowsers(d.date)).format(DATE_FORMAT);
  });
};

// React UTILS

function isClassComponent(component) {
  return typeof component === "function" && !!component?.prototype?.isReactComponent
    ? true
    : false;
}

function isFunctionComponent(component) {
  return typeof component === "function" &&
    String(component).includes("return React.createElement")
    ? true
    : false;
}

export function isReactComponent(component) {
  return isClassComponent(component) || isFunctionComponent(component) ? true : false;
}

export const setStringifiedLocalStorageData = (itemName, item) => {
  try {
    localStorage.setItem(itemName, JSON.stringify(item));
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const getParsedLocalStorageData = (itemName) => {
  try {
    return JSON.parse(localStorage.getItem(itemName)) || {};
  } catch (error) {
    return {};
  }
};

export const truncateUserName = (name = "", limit = 25) =>
  typeof name === "string"
    ? name.length <= limit
      ? name
      : name.slice(0, limit) + "..."
    : "";

export const transferToDollar = (priceInCents = 0) =>
  formatCurrency(Number(priceInCents) / 100);

/**
 * Get Intercom boot data for user
 *
 * @param {Object} parameters
 * @returns {Object} Intercom data
 */
export const getIntercomBootData = ({
  email,
  uuid,
  firstName,
  lastName,
  intercomHash,
  business,
}) => {
  return {
    email,
    userId: uuid,
    name: `${firstName} ${lastName}`,
    userHash: intercomHash,
    company: {
      companyId: business?.uuid,
      name: business?.name,
      customAttributes: {[INTERCOM_COMPANY_CUSTOM_ATTRIBUTES.businessId]: business?.id},
    },
    hideDefaultLauncher: true, // always launch Intercom messenger by clicking on Help button so default launcher is always hidden
    alignment: "left", // always keep messenger to the left
    verticalPadding: 20, // min value
    horizontalPadding: 20, // min value
    customLauncherSelector: `#${HELP_BUTTON_ID}`,
  };
};

/**
 * Encode any object to base64
 *
 * @param {Object} obj any object
 * @returns {String} Address url
 * @example
 * const encodedObject = encodeBase64({...object});
 */
export const encodeBase64 = (obj) => {
  return btoa(encodeURIComponent(JSON.stringify(obj)));
};

/**
 * Return LiveLink auth link
 *
 * @param {Object} parameters
 * @param {String} parameters.businessId
 * @param {String} parameters.token
 * @param {String | null} parameters.orderToken
 * @param {String} parameters.assets
 * @param {String} [parameters.serviceOrderId]
 * @returns {String} Address url
 * @example
 * const url = composeLiveLinkAuthLink(store.currentBusiness.id, store.token, "encodedStringifyObject");
 */
export const composeLiveLinkAuthLink = ({
  businessId = null,
  token,
  orderToken = null,
  assets = "",
  serviceOrderId,
}) => {
  const pageRoute = businessId ? `order/business/${businessId}` : "verify-account";
  const url = new URL(`${VITE_LIVE_LINK_URL}${pageRoute}`);

  const paramsToUrl = [
    ["service_order_id", serviceOrderId],
    ["assets", assets],
    ["access_token", token],
  ];

  if (orderToken && !serviceOrderId) {
    paramsToUrl.push(["orderToken", orderToken]);
  }

  for (const paramConfig of paramsToUrl) {
    if (paramConfig[1]) {
      url.searchParams.append(...paramConfig);
    }
  }

  return url.toString();
};

export const getActiveSecondaryPage = (pathname, pages = []) =>
  pages.find(({url}) => pathname.match(`.*?${url}[/$]?`));

export const getOtherCountExtraText = (count) => {
  return count > 1 ? ` + ${count - 1} other${count < 3 ? "" : "s"}` : "";
};

/**
 * Determines if a given third party delivery id is a nash job.
 *
 * @param {string} thirdPartyDeliveryId - The third party delivery id to check.
 * @returns {boolean} - True if the third party delivery id is a job, false otherwise.
 */
export const isNashJob = (thirdPartyDeliveryId) => /^job*/.test(thirdPartyDeliveryId);

export const delay = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const convertBytesToMB = (bytes) => {
  if (!bytes) {
    return 0;
  }

  const MB = 1024 ** 2;

  return parseFloat((bytes / MB).toFixed(2));
};
